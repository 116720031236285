import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article}) => {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)
    const {
      defaultTitle,
      titleTemplate,
      defaultDescription,
      siteUrl,
      defaultImage,
      twitterUsername,
      keywords,
    } = site.siteMetadata
    const seo = {
      title: title || defaultTitle,
      description: description,
      image: defaultImage,
      url: `${siteUrl}${pathname}`,
    }
    const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null


    return (
        <Helmet link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
        title={seo.title} titleTemplate={seo.title}>
            <meta property="og:url" content={seo.url} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:type" content="website" />
            <meta name="google-site-verification" content="P-4DzXlQgeDoYsnJCDG1G0munzxCKnGqoQNBQwAsPfg" />

            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
          
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            <meta name="twitter:image" content="https://thinkbetter.blog/darkcoverpic.jpg" />
          
            <meta name="author" content="Harikrishnan"></meta>
            <meta property="og:description" content={seo.description} />
            <meta name="description" content={seo.description} />
            <meta property="og:image" content={seo.image} />


            </Helmet>
      )
  }

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  pathname: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  pathname: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`